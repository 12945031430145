import React from "react"
import Layout from '../components/global/layout.js'
import contactStyles from '../style/contact.module.scss'
import { FormattedMessage } from 'gatsby-plugin-intl'
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { Helmet } from "react-helmet"
import ContactForm from "../components/contact/contact_form.js"






const ContactPage = () => {

    return (

        <Layout
            hideFooter>


            <Helmet>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
                <title> Spherical Elephant | Kontakt </title>
                <meta name="description" content="Wir plaudern echt wahnsinnig gerne! | Erzähl uns von deinem nächsten Projekt, deiner bestehenden Website, oder reden wir einfach über das Wetter."></meta>
            </Helmet>

            <div className={contactStyles.contactContainer}>
                <div className={contactStyles.contactContent}>
                    <div className={contactStyles.contactTitle}>
                        <h1><FormattedMessage id="Nav.contact" /></h1>
                        <p className={contactStyles.title}><FormattedMessage id="Contact.getintouch" /></p>
                        <div className={contactStyles.contactInfo}>
                            <div>
                                <svg viewBox="2 0 24 24">
                                    <path fill="currentColor" d="M12,15C12.81,15 13.5,14.7 14.11,14.11C14.7,13.5 15,12.81 15,12C15,11.19 14.7,10.5 14.11,9.89C13.5,9.3 12.81,9 12,9C11.19,9 10.5,9.3 9.89,9.89C9.3,10.5 9,11.19 9,12C9,12.81 9.3,13.5 9.89,14.11C10.5,14.7 11.19,15 12,15M12,2C14.75,2 17.1,3 19.05,4.95C21,6.9 22,9.25 22,12V13.45C22,14.45 21.65,15.3 21,16C20.3,16.67 19.5,17 18.5,17C17.3,17 16.31,16.5 15.56,15.5C14.56,16.5 13.38,17 12,17C10.63,17 9.45,16.5 8.46,15.54C7.5,14.55 7,13.38 7,12C7,10.63 7.5,9.45 8.46,8.46C9.45,7.5 10.63,7 12,7C13.38,7 14.55,7.5 15.54,8.46C16.5,9.45 17,10.63 17,12V13.45C17,13.86 17.16,14.22 17.46,14.53C17.76,14.84 18.11,15 18.5,15C18.92,15 19.27,14.84 19.57,14.53C19.87,14.22 20,13.86 20,13.45V12C20,9.81 19.23,7.93 17.65,6.35C16.07,4.77 14.19,4 12,4C9.81,4 7.93,4.77 6.35,6.35C4.77,7.93 4,9.81 4,12C4,14.19 4.77,16.07 6.35,17.65C7.93,19.23 9.81,20 12,20H17V22H12C9.25,22 6.9,21 4.95,19.05C3,17.1 2,14.75 2,12C2,9.25 3,6.9 4.95,4.95C6.9,3 9.25,2 12,2Z" />
                                </svg>
                                <a href="mailto:office@sphericalelephant.com">office@sphericalelephant.com</a>
                            </div>
                            <div>
                                <svg viewBox="4 0 24 24">
                                    <path fill="currentColor" d="M17,19H7V5H17M17,1H7C5.89,1 5,1.89 5,3V21A2,2 0 0,0 7,23H17A2,2 0 0,0 19,21V3C19,1.89 18.1,1 17,1Z" />
                                </svg>
                                <a href="tel:+4319240281">+43 1 924 02 81</a>
                            </div>
                            <div>
                                <svg viewBox="5 0 21 21">
                                    <path fill="currentColor" d="M12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5M12,2A7,7 0 0,1 19,9C19,14.25 12,22 12,22C12,22 5,14.25 5,9A7,7 0 0,1 12,2M12,4A5,5 0 0,0 7,9C7,10 7,12 12,18.71C17,12 17,10 17,9A5,5 0 0,0 12,4Z" />
                                </svg>
                                Anton Wild-Gans-Gasse 4, 2380 Perchtoldsdorf

                            </div>
                        </div>
                    </div>
                    <ContactForm />

                </div>
            </div>



            <a target="_blank" rel="noreferrer" href="https://maps.app.goo.gl/8rMNa9eAyVEWETHq8">
                <div className={contactStyles.mapContainer}>
                    <div />
                </div>
            </a>

            <div className={contactStyles.impressum}>
                <nav>
                    <AniLink cover bg="#F2F2F2" duration={1} direction="right" partiallyActive={true} to="../imprint"><FormattedMessage id="Nav.imprint" /></AniLink>
                </nav>
            </div>
        </Layout>


    )
}

export default ContactPage


